@import '../../Colors';

#description-container {
  width: 100vw;
  height: 100vh;
  left: 0;
  bottom: -100%;
  font-size: 10px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-content: center;
  transition: 0.4s;
  z-index: 1;

  &.active {
    bottom: 0%; }

  #inactive-area {
    width: 100vw;
    height: 100vh;
    position: absolute; }

  #description-area {
    color: $main-text-color;
    width: 75%;
    height: 75vh;
    box-shadow: 3px 0 #00000050;
    background-color: #3c3c3c;
    border-radius: 25px 25px 0 0;
    position: absolute;
    bottom: 0;
    margin: 0;

    & > div {
      margin: 15px; } }

  a {
    color: inherit; }

  p {
    font-size: 16px;
    line-height: 25px; }

  p span {
    color: $sub-text-color; }

  #description-title {
    font-size: 40px;

    #return-button {
      cursor: pointer;
      display: inline-block;
      position: absolute;
      width: 40px;
      height: 40px;
      background: #545454;
      text-align: center;
      line-height: 39px;
      border-radius: 100px; }

    #description-name h2 {
      text-align: left;
      margin-left: 50px;
      width: 90%;
      position: relative;
      font-size: 30px;
      line-height: 40px; } }

  #description-data {
    overflow: auto;
    height: 60vh;
    display: flex;
    justify-content: space-between;

    .description-content {
      width: 45%; }

    &::-webkit-scrollbar {
      width: 8px;
      height: 8px; }

    &::-webkit-scrollbar-track {
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px; }

    &::-webkit-scrollbar-thumb {
      background-color: #dfdfdf;
      border-radius: 10px; } }


  @media screen and (max-width: 769px) {
    #description-area {
      width: 100%;
      height: 85vh; }

    #description-data {
      flex-direction: column;

      .description-content {
        width: 100%; } } } }
