body {
  font-family: 'Montserrat Alternates', sans-serif;
  background-color: #4c4c4c; }

h2 {
  font-family: 'Azonix';
  font-size: 20px; }

header {
  width: 90%;
  margin: 0 auto;

  h1 {
    text-align: center;
    color: #fff;
    padding: 15px;
    font-size: 25px; }

  #logo-header {
    display: inherit;
    width: 350px;
    max-width: 80%;
    position: relative;
    padding: 15px 0;
    margin: 0 auto;
    filter: invert(1); } }

.container#content {
  width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  margin: 30px auto; }

#legal {
  text-align: center;
  width: 70%;
  position: relative;
  margin: 20px auto;
  color: #a2a2a2; }

#legal h3 {
  font-size: 12px; }

#legal p {
  font-size: 10px;
  font-weight: 100; }

#legal a {
  color: #a2a2a2; }
