@import url(https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap);
#loading{width:100vw;height:100vh;display:flex;align-items:center;justify-content:center;font-size:40px;color:#fff}

#description-container{width:100vw;height:100vh;left:0;bottom:-100%;font-size:10px;position:fixed;display:flex;justify-content:center;align-content:center;transition:0.4s;z-index:1}#description-container.active{bottom:0%}#description-container #inactive-area{width:100vw;height:100vh;position:absolute}#description-container #description-area{color:#fff;width:75%;height:75vh;box-shadow:3px 0 #00000050;background-color:#3c3c3c;border-radius:25px 25px 0 0;position:absolute;bottom:0;margin:0}#description-container #description-area>div{margin:15px}#description-container a{color:inherit}#description-container p{font-size:16px;line-height:25px}#description-container p span{color:#afafaf}#description-container #description-title{font-size:40px}#description-container #description-title #return-button{cursor:pointer;display:inline-block;position:absolute;width:40px;height:40px;background:#545454;text-align:center;line-height:39px;border-radius:100px}#description-container #description-title #description-name h2{text-align:left;margin-left:50px;width:90%;position:relative;font-size:30px;line-height:40px}#description-container #description-data{overflow:auto;height:60vh;display:flex;justify-content:space-between}#description-container #description-data .description-content{width:45%}#description-container #description-data::-webkit-scrollbar{width:8px;height:8px}#description-container #description-data::-webkit-scrollbar-track{background-color:rgba(0,0,0,0.4);border-radius:10px}#description-container #description-data::-webkit-scrollbar-thumb{background-color:#dfdfdf;border-radius:10px}@media screen and (max-width: 769px){#description-container #description-area{width:100%;height:85vh}#description-container #description-data{flex-direction:column}#description-container #description-data .description-content{width:100%}}

.computer-container{text-align:center;background:#3e3e3e;border-radius:10px;width:300px;height:260px;display:inline-grid;position:inherit;margin:20px;box-shadow:2px 2px 10px #00000033;cursor:pointer}.computer-container .computer-name{text-align:left;color:#fff;margin-left:10px}.computer-container .rank{background:#369df9;color:#fff;width:40px;height:40px;line-height:40px;border-radius:100px;position:absolute;margin-top:-20px;right:5px}.computer-container .info-container p{font-size:12px;color:#afafaf;padding:0 10px;text-align:left}.computer-container .image-container{width:300px;height:150px;margin:0 auto;background-size:430px;border-radius:10px 10px 0 0;background-position:center;animation:1s;-webkit-filter:grayscale(0.7);filter:grayscale(0.7);transition:0.5s}.computer-container .computer-container:hover .image-container{background-size:500px;-webkit-filter:none;filter:none}.computer-container img.img-supercomputer{width:inherit}

#computers{position:relative;display:flex;flex-direction:column;flex-flow:wrap;justify-content:center}

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 14, 2020 */@font-face{font-family:'Azonix';src:url(/static/media/azonix-1vb0-webfont.a31f07f9.woff2) format("woff2"),url(/static/media/azonix-1vb0-webfont.799245d1.woff) format("woff");font-weight:normal;font-style:normal}

body{font-family:'Montserrat Alternates', sans-serif;background-color:#4c4c4c}h2{font-family:'Azonix';font-size:20px}header{width:90%;margin:0 auto}header h1{text-align:center;color:#fff;padding:15px;font-size:25px}header #logo-header{display:inherit;width:350px;max-width:80%;position:relative;padding:15px 0;margin:0 auto;-webkit-filter:invert(1);filter:invert(1)}.container#content{width:90%;display:flex;flex-direction:column;justify-content:center;position:relative;margin:30px auto}#legal{text-align:center;width:70%;position:relative;margin:20px auto;color:#a2a2a2}#legal h3{font-size:12px}#legal p{font-size:10px;font-weight:100}#legal a{color:#a2a2a2}

