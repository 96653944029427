/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on July 14, 2020 */

@font-face {
    font-family: 'Azonix';
    src: url('azonix-1vb0-webfont.woff2') format('woff2'),
         url('azonix-1vb0-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates&display=swap');