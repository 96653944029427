@import '../../Colors';

.computer-container {
  text-align: center;
  background: #3e3e3e;
  border-radius: 10px;
  width: 300px;
  height: 260px;
  display: inline-grid;
  position: inherit;
  margin: 20px;
  box-shadow: 2px 2px 10px #00000033;
  cursor: pointer;

  .computer-name {
    text-align: left;
    color: #fff;
    margin-left: 10px; }

  .rank {
    background: $main-color;
    color: #fff;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100px;
    position: absolute;
    margin-top: -20px;
    right: 5px; }

  .info-container p {
    font-size: 12px;
    color: $sub-text-color;
    padding: 0 10px;
    text-align: left; }

  .image-container {
    width: 300px;
    height: 150px;
    margin: 0 auto;
    background-size: 430px;
    border-radius: 10px 10px 0 0;
    background-position: center;
    animation: 1s;
    filter: grayscale(0.7);
    transition: 0.5s; }

  .computer-container:hover .image-container {
    background-size: 500px;
    filter: none; }

  img.img-supercomputer {
    width: inherit; } }
